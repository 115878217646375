const Lang = {
  'en': {
    'you':'you',
    'today': 'Today at',
    'yesterday': 'Yesterday at',
    'placeholder':'Type a message...',
    'send':'send'
  },
  'se': {
    'you':'du',
    'today': 'Idag vid',
    'yesterday': 'Igår vid',
    'placeholder':'skriv ett meddelande...',
    'send':'skicka'
  }
}

export default Lang;