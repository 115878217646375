const Lang = {
  'en': {
    'header':'Messages',
    'today': 'Today at',
    'yesterday': 'Yesterday at'
  },
  'se': {
    'header':'Meddelanden',
    'today': 'Idag vid',
    'yesterday': 'Igår vid'
  }
}

export default Lang;