import { auth } from "../config/firebase-config";
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  FacebookAuthProvider, 
  GoogleAuthProvider, 
  GithubAuthProvider,
  TwitterAuthProvider,
  signOut
} from 'firebase/auth';
import { addUser, addNonSocUser } from './db';


/* const socialMediaAuth = (provider) => {
  return signInWithPopup(auth, provider)
  .catch((err) => {
    console.error(err);
    alert(err.message);
  })
}; */


const socialMediaAuth = async (provider) => {
  try {
    // Attempt sign-in with the selected provider
    const result = await signInWithPopup(auth, provider);
    console.log("Signed in successfully:", result.user);

    return result.user; // Return the signed-in user
  } catch (err) {
    if (err.code === "auth/account-exists-with-different-credential") {
      // Handle account linking
      const pendingCredential = err.customData.credential; // Get the credential for linking
      const email = err.customData.email; // Get the email associated with the error

      // Fetch sign-in methods for the conflicting email
      const methods = await fetchSignInMethodsForEmail(auth, email);

      if (methods.includes("password")) {
        // If the user previously signed up with email/password, prompt them to log in
        alert("Please sign in with your email and password first to link accounts.");
      } else if (methods.length > 0) {
        // If the user has another provider (e.g., Google), trigger that provider's sign-in
        const linkedProvider = getProviderForMethod(methods[0]); // Convert method to provider
        const linkedResult = await signInWithPopup(auth, linkedProvider);

        // Link the accounts
        await linkWithCredential(linkedResult.user, pendingCredential);
        alert("Accounts successfully linked!");
      }
    } else {
      console.error("Error signing in:", err);
      alert(err.message);
    }
  }
};

// Helper function to get a provider object based on a method
const getProviderForMethod = (method) => {
  switch (method) {
    case "google.com":
      return new GoogleAuthProvider();
    case "facebook.com":
      return new FacebookAuthProvider();
    case "twitter.com":
      return new TwitterAuthProvider();
    default:
      throw new Error("Unsupported provider");
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    addNonSocUser({
      uid: user.uid,
      name: name,
      email: email
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);

};

export { socialMediaAuth, registerWithEmailAndPassword, logInWithEmailAndPassword, sendPasswordReset,logout };