import "./Contact.css";
import React, { useEffect,useState, useCallback } from "react";
import { useCookies } from 'react-cookie';
import { AlertCircle, CheckCircle, Loader2 } from 'lucide-react';
import ReCAPTCHA from 'react-google-recaptcha';
import lang from './Lang';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const API_URL = process.env.REACT_APP_API;

export default function Contact(props) {
  const [currLang, setcurrLang] = useState(lang['en']);
  const [cookies, setCookie] = useCookies(['user']);
  const user = cookies.user;
  const [status, setStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);

  }, [user]);

  //if(currLang === undefined) return ( <></>); // TODO: better fix for iphone

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    
    if (!captchaToken) {
      setStatus('error');
      setErrorMessage('Please complete the reCAPTCHA verification');
      return;
    }

    try {
      setStatus('loading');
      const response = await fetch(`${API_URL}contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          captchaToken
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to send message');
      }

      setStatus('success');
      setFormData({ name: '', email: '', message: '' });
      setCaptchaToken(null);
    } catch (error) {
      setStatus('error');
      setErrorMessage(error instanceof Error ? error.message : 'Failed to send message');
    }
  }, [formData, captchaToken]);

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    if (status !== 'idle') setStatus('idle');
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (status === 'error') setStatus('idle');
  };

  return(
    <form onSubmit={handleSubmit} className="container contact-form mt">
        <h2 className="text-center" >{currLang.header}</h2>
        <div className="form-group">
        	<div className="input-group">                
            <div className="input-group-prepend">
              <span className="input-group-text">
                <span className="fa fa-user"></span>
              </span>                    
            </div>
            <label htmlFor="name" className="hide">{currLang.name}</label>
            <input onChange={handleChange} type="text" name="name" className="form-control" placeholder="Jon Doe" />
          </div>
        </div>
        <div className="form-group">
        	<div className="input-group">                
            <div className="input-group-prepend">
              <span className="input-group-text">
                <span className="fa fa-at"></span>
              </span>                    
            </div>
            <label htmlFor="email" className="hide">{currLang.email}</label>
            <input onChange={handleChange} type="email" name="email" className="form-control" placeholder={currLang.email} required="required" />
          </div>
        </div>  
        <div className="form-group">
          <label htmlFor="message">{currLang.message}</label>
          <textarea onChange={handleChange} name="message" className="form-control" rows="4" required="required" />
        </div>  
          <div className="form-group captcha clearfix">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
              theme="light"
              className="float-right"
            />
          </div>  
        <div className="form-group clearfix">
          <button 
            type="submit" 
            disabled={status === 'loading'}
            className="btn btn-success btn-block login-btn float-right">
              {status === 'loading' ? (
                <>
                  <Loader2 className="animate-spin" />
                  {currLang.send}...
                </>
              ) : (
                <>
                  {currLang.send}
                </>
              )}
              
              
          </button>
        </div>
        <div className="form-group text-center">
          {status === 'error' && (
            <div className="alert alert-warning">
              <AlertCircle className="w-3 h-3" />
              {errorMessage || 'An error occurred. Please try again.'}
            </div>
          )}

          {status === 'success' && (
            <div className="alert alert-success2">
              <CheckCircle className="w-4 h-4" />
              Message sent successfully!
            </div>
          )}
          </div>
  </form>
  )
}