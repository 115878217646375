//#region imported
import './App.css';
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useCookies } from 'react-cookie';

//#endregion
//#region components
import NavBar from './components/NavBar/NavBar';
import CookiePopup from './components/CookiePopup/CookiePopup';
import Footer from './components/Footer/Footer';

//#endregion
//#region user pages
import Login from './pages/User/Login/Login';
import Logout from './pages/User/Logout/Logout';
import Register from './pages/User/Register/Register';
import ResetPassword from './pages/User/ResetPassword/ResetPassword';
import RegProduct from './pages/User/RegProduct/RegProduct';
import Dashboard from './pages/User/Dashboard/Dashboard';
import ViewProduct from './pages/ViewProduct/ViewProduct';
import RegAdress from './pages/User/RegAdress/RegAdress';
import Order from './pages/User/Order/Order';
import Message from './pages/User/Message/Message';
import Messages from './pages/User/Messages/Messages';

//#endregion
//#region pages
import Cookies from './pages/Cookies/Cookies';
import Home from './pages/Home/Home';
import Companies from './pages/Companies/Companies';
import NoMatch from './pages/NoMatch/NoMatch';
import Support from './pages/Support/Support';
import Terms from './pages/Terms/Terms';
import Contact from './pages/Contact/Contact';
//#endregion

const price = { 
  'se': {
    single: {month: 30, year: 300},
    tripple: {month: 60, year: 600},
    sign: 'kr'
  },
  'en': {
    single: {month: 3, year: 30},
    tripple: {month: 6, year: 60},
    sign: '€'
  }
}

function App() {
  const [cookies] = useCookies(['user']);
  const user = cookies.user;
  let userLang = navigator.language;
  userLang = userLang.toLowerCase().split('-');
  if(userLang === 'sv' || userLang[1] === 'sv' || userLang[0] === 'sv') {
    userLang = 'se';
  } else {
    userLang = 'en';
  }
  let [language, setLanguage] = useState(
    userLang
  );
  
  return (
    <div>
      <Router>
        <NavBar user={user} language={language}/>
        <Routes>
          <Route exact path="/" element={<Home language={language} price={price}/>} />
          <Route exact path="/companies" element={<Companies language={language} />} />
          <Route exact path="/login" element={<Login language={language} />} />
          <Route exact path="/logout" element={<Logout language={language} />} />
          <Route exact path="/resetpassword" element={<ResetPassword  language={language} />} />
          <Route exact path="/user/register" element={<Register language={language} />} />
          <Route exact path="/user/messages" element={<Messages language={language} user={user} />} />
          <Route exact path="/user/message/:id" element={<Message language={language} user={user} />} />
          <Route exact path="/user/register-adress" element={<RegAdress language={language} user={user}/>} />          
          <Route exact path="/order" element={<Order language={language} user={user} price={price}/>} />
          <Route exact path="/dashboard" element={<Dashboard language={language} user={user}/>} />
          <Route exact path="/cookies" element={<Cookies language={language} />} />
          <Route exact path="/support" element={<Support language={language} />} />
          <Route exact path="/terms" element={<Terms language={language} />} />
          <Route exact path="/contact" element={<Contact language={language} />} />
          <Route exact path="/product/register" element={<RegProduct user={user} price={price} language={language} />} />
          <Route exact path="/product/edit/:id" element={<RegProduct user={user} price={price} language={language} />} />
          <Route exact path="/product/:id" element={<ViewProduct user={user} language={language} />} />
          <Route path="*" element={<NoMatch />} language={language} />
        </Routes>
        <Footer 
          language={language}
          handleSetLanguage={language => {
            setLanguage(language);
            //TODO: save lang to db ?
          }}
        />
      </Router>
      <CookiePopup language={language} />
    </div>
  );
}

export default App;
