const Lang = {
  'en': {
    'headerEdit':'Edit your product',
    'headerReg': 'Register a product',
    'lead' : 'Here you enter the information on your item and how the finder shall contact you.',
    'name' : 'Name',
    'nameDesc':'Something to identify this item, brand, model or likewise.',
    'findFee':'Finders fee',
    'findFeeDesc' :'A higher finders fee will of course motivate the finder to contact you.',
    'findFeeDesc_Old' :'We will reserve this amount to speed up the return process. A higher finders fee will of course motivate the finder to contact you. Cancel this product and get your finders fee back at any time.',
    'type':'Type',
    'select': 'Please select one',
    'mobile':'Mobile',
    'laptop':'Laptop',
    'other':'Other',
    'contact':'Contact info',
    'contactDesc':'Remember that if you register your phone, you shoudn\'t just leave your phone number. And if you leave your socialmedia contacts, be aware if you have blocked unknown users to contact you. ',
    'package':'Package',
    'single':'Single',
    'tripple':'Tripple',
    'otherFindFees':'Other finders fees',
    'total':'Total price for this item',
    'extraOrderInfo':'To select more than one product, go to dashboard or order when you are finished with this product.',
    'monthly':'Monthly',
    'year':'Year',
    'payNow' :'Save and pay now',
    'payLater':'Save and pay later',
    'updateInfo' : 'Update info',
    'explain':'Minimum 6 months'
  },
  'se': {
    'headerEdit':'Redigera din produkt',
    'headerReg': 'Registera din produkt',
    'lead' : 'Här skriver du in informationen om din produkt och hur personen som hittar din produkt ska kunna kontakta dig.',
    'name' : 'Namn',
    'nameDesc':'En beskrivning för att identifiera just denna produkt, t ex märke, model eller liknande.',
    'findFee':'Hittelön',
    'findFeeDesc' :' En högre hittelön motiverar så klart personen som hittar din produkt att återlämna den.',
    'findFeeDesc_old' :'Vi reserverar detta belopp för att snabba på processen för att återfå din produkt när den hittas. En högre hittelön motiverar så klart personen som hittar din produkt att återlämna den. Om du avslutar vår tjänst får du givetvis tillbaka din hittelön.',
    'type':'Typ',
    'select': 'vänligen välj typ',
    'mobile':'Mobil',
    'laptop':'Laptop',
    'other':'Annat',
    'contact':'Kontakt information',
    'contactDesc':'OBS!! Om du registerar din mobil så kan du inte ange ditt mobil nummer för personen som hittar telefonen att kontakta dig. Och tänk på om du anger dina sociala medier, om dom är inställda på att blocka okända personer.  ',
    'package':'Paket',
    'single':'Singel',
    'tripple':'Trippel',
    'otherFindFees':'Andra hittelöner',
    'total':'Totalt pris för denna produkt',
    'extraOrderInfo':'För att välja mer än en produkt, spara denna produkt och gå till aktivera produkter',
    'monthly':'Per månad',
    'year':'Per år',
    'payNow' :'Spara och betala nu',
    'payLater':'Spara och betala senare',
    'updateInfo':'Uppdatera info',
    'explain':'Minst 6 månader'
  }
}

export default Lang;