import "./Message.css";
import React, { useEffect, useState } from "react";
import { updateUser } from "../../../service/db";
// import { auth, db, provider } from "./firebase";
//import { collection, addDoc, query, orderBy } from "firebase/firestore";
//import { useCollectionData } from "react-firebase-hooks/firestore";
import { format, isToday, isYesterday } from "date-fns";
import { useNavigate } from "react-router-dom";
import lang from './Lang';


export default function Message(props) {

    const navigate = useNavigate();
    const [currLang, setcurrLang] = useState(lang['en']);
  
    useEffect(() => {
      if (!props) {
        // maybe trigger a loading screen
        return;
      }

      if (props.language) setcurrLang(lang[props.language]);
      if(!props.user.name) navigate('/');
    }, [props.user]);
  
    const formatTimestamp = (timestamp) => {
      const date = new Date(timestamp);
      if (isToday(date)) return `${currLang.today} ${format(date, "HH:mm")}`;
      if (isYesterday(date)) return `${currLang.yesterday} ${format(date, "HH:mm")}`;
      return format(date, "dd/MM/yyyy HH:mm");
    };
  
    const oneHourAgo = new Date(new Date().getTime() - 60 * 60 * 1000); // 1 hour ago
    const fifteenMinutesAgo = new Date(new Date().getTime() - 15 * 60 * 1000); // 15 minutes ago
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)); // Yesterday
    const longtime = new Date(new Date().setDate(new Date().getDate() - 7)); // Yesterday
  

    const [messages, setMessages] = useState([
        { sender: "A", text: "Hello! How are you?", sent: false, timestamp: formatTimestamp(longtime) },
        { sender: "You", text: "I'm good! How about you?", sent: true, timestamp: formatTimestamp(yesterday) },
        { sender: "A", text: "good, Found your phone", sent: false, timestamp: formatTimestamp(oneHourAgo) },
        { sender: "You", text: "omg your an angel", sent: true, timestamp: formatTimestamp(fifteenMinutesAgo) }
    ]);
    const [input, setInput] = useState("");

    const sendMessage = (e) => {
        e.preventDefault();
        if (input.trim() !== "") {
            setMessages([...messages, { sender: "You", text: input, sent: true, timestamp: formatTimestamp(new Date()) }]);
            setInput("");
            //TODO: save to db 
        }
    };

    return (
    <div className="container mt chat">
        <div className="card mb-4 shadow-sm chat-box">
            <h4>My Phone</h4>
            {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sent ? "sent" : ""}`}>
                    <div className="avatar">{msg.sender}</div>
                    <div className="message-content">
                        <div>{msg.text}</div>
                        <small className="text-muted">{msg.timestamp}</small>
                    </div>
                </div>
            ))}
        </div>
        <div className="input-group p-2">
            <form onSubmit={sendMessage}>
                <input type="text" value={input} onChange={(e) => setInput(e.target.value)} className="form-control" placeholder={currLang.placeholder} />
                <button className="btn btn-success" type="submit">{currLang.send}</button>
            </form>
        </div>
    </div>
);
};


