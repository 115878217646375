import './ViewProduct.css';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { doc,  getDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { userPos } from "../../service/geo";
import lang from './Lang';

const makeUrl = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *  charactersLength));
  }
  return result;
}

export default function ViewProduct(props) {
  const [product, setProduct] = useState("");
  const fakeUrl = makeUrl(400);
  const [currLang, setcurrLang] = useState(lang['en']);
  const user = props.user;

  // TODO: message owner that product page is viewed and position of viewer

  const fetchUserLocation = async() => {
    const l = await userPos('link');
    console.log(l);
  }

  const fetchProduct = async () => {
    try {
      const pathname = window.location.pathname
      window.history.replaceState(null, null, '/'+ fakeUrl);
      const res = await getDoc(doc(db, 'products', pathname.replace("/product/","")))
      setProduct(res.data());
      fetchUserLocation();
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching product data");
    }
  };

  useEffect(() => {
    if(!props) return;
    if(!product) fetchProduct();
    if (props.language) setcurrLang(lang[props.language]);

  }, [product.owner, props]);

  // Not paid and not owner

  if(product.owner && (!user || product.owner !== user.ref) && !product.payed){
    return (
      <div className='product'>
        <div className="alert alert-info" role="alert">
          {currLang.notPaid}
        </div>
      </div>
      
    );
  }

  // payed or prod owner

  if(product.payed || (user && (product.owner === user.ref))) { 
    return (
    <div className='product'>
      { (user && product.owner === user.ref && !product.payed) ? ( 
        <div className="alert alert-warning" role="alert">
            {currLang.notActivated}<Link to="/order" className="alert-link"> {currLang.activate}</Link>.
        </div>
      ): ( <div></div>) }
      <div className="card-deck mb-3">
        <div className="card mb-4 shadow-sm">
          <div className="card-header text-center">
            <h1 className="my-0 font-weight-normal">{currLang.header}</h1>
          </div>
          <div className="card-body">
            <div className="alert alert-success text-center">
              <h2>{currLang.findFee}:{product.findFee}</h2>
            </div>
            <h3>{currLang.contact}:</h3>
            <p className="prod-info">{product.contactInfo}</p>
            <h4>{currLang.item}:</h4>
            <table className="table table-striped">
              <tbody>
              <tr>
                  <th scope="row">{currLang.type}:</th>
                  <td>{product.type}</td>
                </tr>
                <tr>
                  <th scope="row">{currLang.name}:</th>
                  <td>{product.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )};

  return (
    <div></div>
  )
}
