import "./Messages.css";
import React, { useEffect, useState} from "react";
import { format, isToday, isYesterday } from "date-fns";
import { useNavigate } from "react-router-dom";
import lang from './Lang';

export default function Messages(props) {

  const navigate = useNavigate();
  const user = props.user;

  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (!props) {
      // maybe trigger a loading screen
      return;
    }
    if (props.language) setcurrLang(lang[props.language]);
    if(!props.user.name) navigate('/');
  }, [props.user]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    if (isToday(date)) return `${currLang.today} ${format(date, "HH:mm")}`;
    if (isYesterday(date)) return `${currLang.yesterday} ${format(date, "HH:mm")}`;
    return format(date, "dd/MM/yyyy HH:mm");
  };

  const oneHourAgo = new Date(new Date().getTime() - 60 * 60 * 1000); // 1 hour ago
  const fifteenMinutesAgo = new Date(new Date().getTime() - 15 * 60 * 1000); // 15 minutes ago
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)); // Yesterday
  const longtime = new Date(new Date().setDate(new Date().getDate() - 7)); // Yesterday

  const messages = [
      { id: 1, product: "Iphone", name: "Alice", lastMessage: "Hey, found your phone", timestamp: formatTimestamp(fifteenMinutesAgo) },
      { id: 2, product: "Wallet", name: "Bob", lastMessage: "See you later!", timestamp: formatTimestamp(oneHourAgo) },
      { id: 3, product: "keys", name: "Charlie", lastMessage: "Let's meet tomorrow", timestamp: formatTimestamp(yesterday)},
      { id: 4, product: "other", name: "Bob", lastMessage: "Long time ago", timestamp: formatTimestamp(longtime)}
  ];

  const selectMessage = (id) => {
    navigate('/user/message/' + id)
};

  return (
    <div className="container mt">
          <h1>{currLang.header}</h1>
          <hr></hr>
          <ul className="card list-group">
              {messages.map(msg => (
                  <li key={msg.id} className="message-list-item" onClick={() => selectMessage(msg.id)}>
                      <h4 className="my-0 font-weight-normal">{msg.product}</h4>
                      <div>{msg.name} : {msg.lastMessage}</div>
                      <small className="chat-timestamp text-muted">{msg.timestamp}</small>
                  </li>
              ))}
          </ul>
      </div>
  );
};

